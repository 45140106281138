<template>
    <jet-nav-link :href="route('dashboard')" class="text-white">
        <img :src="asset('img/bewinkel-logo.png')" class="max-w-sm"/></jet-nav-link>
</template>

<script>
    import { defineComponent } from 'vue'
    import { Link } from '@inertiajs/vue3';

    export default defineComponent({
        components: {
            Link,
        },
    })
</script>
